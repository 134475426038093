<template>
  <div>
    <router-view />
    <van-tabbar v-model="active" z-index="77" active-color="#186AFF" placeholder inactive-color="#333333" safe-area-inset-bottom>
      <van-tabbar-item name="/home" to="/home">
        <span>服务预约</span>
        <template #icon="props">
          <img :src="props.active ? homeIcon.active : homeIcon.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="/user" to="/user">
        <span>个人中心</span>
        <template #icon="props">
          <img :src="props.active ? userIcon.active : userIcon.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
  import { Tabbar, TabbarItem } from 'vant';
  const TabbarHome = require('@/assets/images/tabbar/tabbar_home.svg')
  const TabbarHomeCur = require('@/assets/images/tabbar/tabbar_home_cur.svg')
  const TabbarUser = require('@/assets/images/tabbar/tabbar_user.svg')
  const TabbarUserCur = require('@/assets/images/tabbar/tabbar_user_cur.svg')

  export default {
    components: {
      [Tabbar.name]: Tabbar,
      [TabbarItem.name]: TabbarItem
    },
    created() {
      this.active = this.$route.path
    },
    watch: {
      $route(val) {
        this.active = val.path
      }
    },
    data() {
      return {
        active: 'home',
        homeIcon: {
          active: TabbarHomeCur,
          inactive: TabbarHome
        },
        userIcon: {
          active: TabbarUserCur,
          inactive: TabbarUser
        }
      }
    }
  }
</script>

<style lang="less">

</style>